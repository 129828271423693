import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { PROJECT_VERSION, AUTHENTICATION_CONFIG, CULTURE_CONFIG, RESOURCE_CONTROL_SERVICE_BASE_PATH, FuseModule, MENU_HEADER_CONFIGURATION, MenuHeaderConfiguration, NAVIGATION_CONF, PROFILE_BASE_PATH, SERVICE_BASE_PATH, TAB_SERVICE_PROVIDER, DirectiveUtils } from '@fidcare/base-gui-lib';
import { BASE_PATH as PAS_12_BASE_PATH, UtenteService } from '@fidcare/pas-api';


import { appConfig } from '@fidcare/base-gui-lib';
import { LayoutModule } from '@fidcare/base-gui-lib';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { FuseConfigModule } from '@fidcare/base-gui-lib';
import { environment } from 'environments/environment';
import { ComponentsModule } from '@fidcare/base-gui-lib';
import { StartupModule } from '@fidcare/base-gui-lib';
import { DDLService, BASE_PATH as DDL_BASE_PATH} from '@fidcare/ddl-api';
import { CART_SERVICE_BASE_PATH } from '@fidcare/base-gui-lib';

import {HfmService, StruttureService, BASE_PATH as HFM_BASE_PATH} from "@fidcare/hfm-api";


import { ACTION_BUTTONS_DESCRIPTION_PROVIDER } from '@fidcare/base-gui-lib';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from '@fidcare/base-gui-lib';
import { navigation } from './navigation';
import { ButtonService, BASE_PATH as HOP_BASE_PATH, TabsService } from '@fidcare/hop-api';
import { ActionButtonProvider } from './hop-module/services/action-buttons.providel.component';
import packageInfo from '../../package.json';

const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
};

var menuConf: MenuHeaderConfiguration = {
	agendaButtonConfiguration: { enabled: false },
	profileButtonConfiguration: {enabled: true, routerLink: "/profile"},
	notificationButtonConfiguration: {enabled: false},
	quickmenuButtonConfiguration: { enabled: false }
}
export let AppInjector: Injector;

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ComponentsModule.forRoot(environment.forms),
        RouterModule.forRoot(appRoutes, routerConfig),
        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),

        // Core module of your application
        StartupModule.forRoot(environment.culture),

        // Layout module of your application
        LayoutModule,

        // 3rd party modules that require global configuration via forRoot
        MarkdownModule.forRoot({})
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
		ButtonService,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{ provide: PROFILE_BASE_PATH, useValue: environment.services.api.pasBasePath },
        { provide: AUTHENTICATION_CONFIG, useValue: environment.authentication },
        { provide: CULTURE_CONFIG, useValue: environment.culture },
        { provide: SERVICE_BASE_PATH, useValue: environment.services.api.serviceBasePath },
		{ provide: PAS_12_BASE_PATH, useValue: environment.services.api.pasBasePath },
		{ provide: NAVIGATION_CONF, useValue: navigation},
		{ provide: TAB_SERVICE_PROVIDER, useClass: TabsService },
		{ provide: MENU_HEADER_CONFIGURATION, useValue: menuConf},
		{ provide: DDL_BASE_PATH, useValue: environment.services.api.ddlBasePath },
        { provide: HOP_BASE_PATH, useValue: environment.services.api.hopBasePath },
		{ provide: ACTION_BUTTONS_DESCRIPTION_PROVIDER, useClass: ActionButtonProvider},
        { provide: PROJECT_VERSION, useValue: packageInfo.version},
        { provide: CART_SERVICE_BASE_PATH, useValue: environment.services.api.ddlBasePath },
        { provide: RESOURCE_CONTROL_SERVICE_BASE_PATH, useValue: environment.services.api.rcsBasePath },
        { provide: HFM_BASE_PATH, useValue: environment.services.api.hfmBasePath },

        
        StruttureService,
        DDLService,
        TabsService,
        DirectiveUtils,
        UtenteService
    ]
})
export class AppModule {
	constructor(private injector: Injector) {
		AppInjector = this.injector;
	}	
}
