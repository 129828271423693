import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, ContextSwitchUtils, DirectiveUtils, ProfileService, ResourceControlService, SnackBarService, SpinnerService } from '@fidcare/base-gui-lib';
import { StruttureService } from '@fidcare/hfm-api';
import { ButtonResponse, ButtonService } from '@fidcare/hop-api';
import { environment } from 'environments/environment';
import jwt_decode from "jwt-decode";
import { catchError, of } from 'rxjs';
@Component({
	selector: 'hop-home-page',
	templateUrl: './home-page.component.html',
	styleUrls: ['./home-page.component.scss']
})
export class HOPHomePage implements OnInit {
	// @HostBinding('className') componentClass: string;
	buttonBackgrounds = [];

	buttons: ButtonResponse[];
	isLoading: boolean;
	userRoles: string[];

	gridTemplateColumns: string;
	pageTitle: "Homecare";

	contentClass = {
		"display": "flex",
		"height": "100%",
		"justify-content": "center",
		"align-items": "center",
		"flex-grow": "1",
		"background-image": "url('/assets/homepage-bg.jpg')",
		"background-repeat": "no-repeat",
		"background-size": "cover",
		"background-position": "center center",
		"border-radius": "10px"
	}

	constructor(
		private readonly router: Router,
		private readonly authService: AuthService,
		// private readonly stateService: StateService,
		private readonly buttonsService: ButtonService,
		private activatedRoute: ActivatedRoute,
		private _spinnerService: SpinnerService,
		private _snackbarService: SnackBarService,
		private _struttureService: StruttureService,
		private _profileService: ProfileService,
		private _rcsService: ResourceControlService,
		private _directiveUtils: DirectiveUtils
	) {
	}

	private fetchData(): void {
		this.isLoading = true;
		let token: any = jwt_decode(this.authService.getAccessToken());
		this.userRoles = (token.realm_access && token.realm_access.roles)? token.realm_access.roles.map(r => r.toLowerCase()): [];
		let structureid = localStorage.getItem("structureId");
		this.buttonsService.getAllByContext(structureid).subscribe((res) => {
			this.buttons = res?.sort(
				(a, b) => a.position - b.position
			);

			this.isLoading = false;

			const templateColumns = Math.min(this.buttons?.length || 0, 3);
			this.gridTemplateColumns = `repeat(${templateColumns}, 1fr)`;

			this.buttons.forEach((element) => {
				this.buttonBackgrounds
					.push(`linear-gradient(to right bottom,${element.buttonColor.color1} 0%,${element.buttonColor.color1} 49%,${element.buttonColor.color2} 51%)`);
			});
		});
	}

	ngOnInit(): void {
		// setTimeout(() => this.stateService.sidenavRequired = false)

		this.activatedRoute.queryParams.subscribe(params => {

            this._spinnerService.show();


            try{

                var structureCode = params['codiceAdt'];
    
                if(structureCode){
    
                    this._struttureService.getStructureByAdtCode(structureCode)
                    .pipe(catchError((err) => {

						this.fetchData();
                        this._spinnerService.hide()
                        this._snackbarService.info("Nessuna Struttura trovata per codice ADT");
                        return of()

                    }))
                    .subscribe(structureResponse => {
						
                        if(structureResponse){

                            var selectedStructure = this._profileService.roles.find(r => r.id == structureResponse.id);

                            if(selectedStructure){
                       

								this._profileService.selectedRole.next(selectedStructure);
								localStorage.setItem(ContextSwitchUtils.getStructureIdLabel(), selectedStructure.id);

								this._rcsService.initCache()

								.pipe(catchError((err) => {

									this.fetchData();
									this._spinnerService.hide()
									this._snackbarService.info("Si è verificato un errore imprevisto")
									return of();

								}))
								.subscribe(cacheResponse => {
								
									this._directiveUtils.refreshProtectedByScopeDirective();
									this.fetchData();
									this._spinnerService.hide()
								})
                                
                      

                            } else{

                                this._spinnerService.hide()
                                this._snackbarService.info("Nessuna Struttura trovata per codice ADT");

                            }
                            
                        } else{

                            this._spinnerService.hide()
                            this._snackbarService.info("Nessuna Struttura trovata per codice ADT");

                        }
                    })
                } else{
					this._spinnerService.hide();
                    this.fetchData();
                }

            } catch(err){

				this.fetchData();
                this._spinnerService.hide();

            }
        })

		

	}

	ngOnDestroy(): void {
		// setTimeout(() => this.stateService.sidenavRequired = true)
	}

	switchTo(url: string, openInNewTab: boolean): void {
		const urlHasProtocol = url.startsWith('https://') || url.startsWith('http://') || url.startsWith('ftp://');
		if (openInNewTab) {
			if (urlHasProtocol) {
				window.open(url, '_blank');
			} else if (url == 'settings/list') {
				window.open('https://' + url, '_blank');
			} else {
				window.open(environment.services.api.serviceBasePath + '/' + url, '_blank');
			}
		} else {
			if (urlHasProtocol) {
				location.href = url;
			} else if (url.startsWith('www.')) {
				location.href = 'https://' + url;
			} else if (url == 'settings/list') {
				this.router.navigate([url]); // relative url handled by routing
			} else {
				location.href = environment.services.api.serviceBasePath + '/' + url;
			}
		}
	}
}